var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.mostrarModalConfirmacao
        ? _c("ConfirmacaoModal", {
            attrs: {
              showDialog: _vm.mostrarModalConfirmacao,
              tituloModal: _vm.tituloModalConfirmacao,
              textoModal: _vm.textoModalConfirmacao,
              btnConfirmarEmit: _vm.btnConfirmarModalConfirmacao,
              esconderCancelar: _vm.esconderCancelar,
              btnConfirmar: _vm.btnConfirmarLabel
            },
            on: {
              invalidarInstrumento: _vm.invalidarInstrumento,
              alterarAgrupador: _vm.alterarAgrupador,
              scrollAnexoProtesto: _vm.scrollAnexoProtesto,
              fecharModal: function($event) {
                _vm.mostrarModalConfirmacao = false
                _vm.esconderCancelar = false
              }
            }
          })
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
        [
          _c(
            "v-container",
            [
              _c(
                "p",
                { staticClass: "title" },
                [
                  _c("v-icon", { staticClass: "ma-2" }, [
                    _vm._v("mdi-information-outline")
                  ]),
                  _vm._v("Informações do Título: ")
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "2", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Código do Status",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.cd_status,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "cd_status", $$v)
                          },
                          expression: "titulo.cd_status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "6", lg: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Descrição da Situação",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.getDescSituacao,
                          callback: function($$v) {
                            _vm.getDescSituacao = $$v
                          },
                          expression: "getDescSituacao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "2", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Id",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.id_titulo,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "id_titulo", $$v)
                          },
                          expression: "titulo.id_titulo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "2", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Nosso Número",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.ds_nosso_numero,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "ds_nosso_numero", $$v)
                          },
                          expression: "titulo.ds_nosso_numero"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Número do Título",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.ds_numero_titulo,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "ds_numero_titulo", $$v)
                          },
                          expression: "titulo.ds_numero_titulo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "1" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Parcela",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.nu_parcela,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "nu_parcela", $$v)
                          },
                          expression: "titulo.nu_parcela"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "3", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Data de Emissão",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.formatDataEmissaoInline,
                          callback: function($$v) {
                            _vm.formatDataEmissaoInline = $$v
                          },
                          expression: "formatDataEmissaoInline"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "3", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Data de Vencimento",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.formatDataVencimentoInline,
                          callback: function($$v) {
                            _vm.formatDataVencimentoInline = $$v
                          },
                          expression: "formatDataVencimentoInline"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Tipo Declaração",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.tp_declaracao,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "tp_declaracao", $$v)
                          },
                          expression: "titulo.tp_declaracao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Endosso",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.tp_endosso,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "tp_endosso", $$v)
                          },
                          expression: "titulo.tp_endosso"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Valor Protestado",
                          prefix: "R$",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.vl_saldo_protesto_formatado,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.titulo,
                              "vl_saldo_protesto_formatado",
                              $$v
                            )
                          },
                          expression: "titulo.vl_saldo_protesto_formatado"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "3", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Valor Total do Título",
                          prefix: "R$",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.vl_titulo_formatado,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "vl_titulo_formatado", $$v)
                          },
                          expression: "titulo.vl_titulo_formatado"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "2", lg: "1" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          value: _vm.titulo.tp_aceite || " ",
                          label: "Aceite",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "1", md: "1", lg: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          value: _vm.titulo.cd_falimentar || " ",
                          label: "Falimentar",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3", lg: "1" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: "Espécie",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.cd_especie_titulo,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "cd_especie_titulo", $$v)
                          },
                          expression: "titulo.cd_especie_titulo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "6", lg: "4" } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          !_vm.usuarioAdmin
                            ? _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  "hide-details": "",
                                  dense: "",
                                  value: _vm.getDescritivoAgrupador(
                                    _vm.$store,
                                    _vm.titulo.ds_agrupador
                                  ),
                                  label: "Agrupador",
                                  required: "",
                                  readonly: !_vm.showActionSalvar
                                }
                              })
                            : _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  color: "success",
                                  disabled:
                                    _vm.loadingAgrupador ||
                                    !_vm.editandoAgrupador,
                                  loading: _vm.loadingAgrupador,
                                  items: _vm.agrupadores,
                                  label: "Agrupador",
                                  required: "",
                                  "item-text": "nm_agrupador",
                                  "item-value": "ds_agrupador",
                                  "data-cy": "tituloAgrupador",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.titulo.ds_agrupador,
                                  callback: function($$v) {
                                    _vm.$set(_vm.titulo, "ds_agrupador", $$v)
                                  },
                                  expression: "titulo.ds_agrupador"
                                }
                              }),
                          _vm.usuarioAdmin
                            ? _c(
                                "div",
                                [
                                  _vm.editandoAgrupador
                                    ? _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "my-1 ml-2 mr-1 pa-2",
                                              attrs: {
                                                "x-small": "",
                                                elevation: "0",
                                                fab: "",
                                                loading: _vm.loadingAgrupador,
                                                "data-cy": "salvarAgrupador"
                                              },
                                              on: {
                                                click: _vm.modalAlterarAgrupador
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi mdi-check")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "my-1 mr-2 ml-1 pa-2",
                                              attrs: {
                                                "x-small": "",
                                                elevation: "0",
                                                fab: "",
                                                loading: _vm.loadingAgrupador
                                              },
                                              on: {
                                                click:
                                                  _vm.cancelarEdicaoAgrupador
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi mdi-close")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-btn",
                                        {
                                          staticClass: "my-1 mx-2 pa-2",
                                          attrs: {
                                            "x-small": "",
                                            elevation: "0",
                                            fab: "",
                                            "data-cy": "editarAgrupador"
                                          },
                                          on: { click: _vm.editarAgrupador }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi mdi-pencil ")
                                          ])
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _vm.superUsuario && _vm.usuarioRecuperi
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", md: "4", lg: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Valor estimado de Emolumentos",
                              required: "",
                              readonly: !_vm.showActionSalvar
                            },
                            model: {
                              value: _vm.valorEmolumento,
                              callback: function($$v) {
                                _vm.valorEmolumento = $$v
                              },
                              expression: "valorEmolumento"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.titulo.vl_repassado != null
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2", md: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "Valor Repasse",
                              required: "",
                              prefix: "R$",
                              readonly: !_vm.showActionSalvar,
                              hint: "Valores repassados pelo cartório"
                            },
                            model: {
                              value: _vm.titulo.vl_repassado,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "vl_repassado", $$v)
                              },
                              expression: "titulo.vl_repassado"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  [101, 107, 103, 121].includes(_vm.titulo.cd_status)
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2", md: "2" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              value: _vm.titulo.vl_custas_formatado || "0,00",
                              label: "Valor Custas",
                              required: "",
                              prefix: "R$",
                              readonly: !_vm.showActionSalvar
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
        [
          _c(
            "v-container",
            [
              _c(
                "p",
                { staticClass: "title" },
                [
                  _c("v-icon", { staticClass: "ma-2" }, [
                    _vm._v("mdi-information-outline")
                  ]),
                  _vm._v("Informações do Devedor "),
                  _c("b", [_vm._v("(Sacado)")]),
                  _vm._v(": ")
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-n8" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "9", md: "9" } },
                    [
                      _vm.checarNomeDevedorCRA(_vm.titulo)
                        ? _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "Devedor Ajustado",
                              required: "",
                              readonly: !_vm.showActionSalvar
                            },
                            model: {
                              value: _vm.titulo.nm_devedor_cra,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "nm_devedor_cra", $$v)
                              },
                              expression: "titulo.nm_devedor_cra"
                            }
                          })
                        : _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "Devedor",
                              required: "",
                              readonly: !_vm.showActionSalvar
                            },
                            model: {
                              value: _vm.titulo.nm_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "nm_devedor", $$v)
                              },
                              expression: "titulo.nm_devedor"
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "9", md: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "CNPJ/CPF",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.formatDocumentoSacadoInline,
                          callback: function($$v) {
                            _vm.formatDocumentoSacadoInline = $$v
                          },
                          expression: "formatDocumentoSacadoInline"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-n8" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "9", md: "8" } },
                    [
                      _vm.checarEnderecoDevedorCRA(_vm.titulo)
                        ? _c("v-text-field", {
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "Endereço Ajustado",
                              required: "",
                              readonly: !_vm.showActionSalvar
                            },
                            model: {
                              value: _vm.titulo.ds_endereco_devedor_cra,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.titulo,
                                  "ds_endereco_devedor_cra",
                                  $$v
                                )
                              },
                              expression: "titulo.ds_endereco_devedor_cra"
                            }
                          })
                        : _c("v-text-field", {
                            staticClass: "parcelas",
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "Endereço",
                              required: "",
                              readonly: !_vm.showActionSalvar
                            },
                            model: {
                              value: _vm.titulo.ds_endereco_devedor,
                              callback: function($$v) {
                                _vm.$set(_vm.titulo, "ds_endereco_devedor", $$v)
                              },
                              expression: "titulo.ds_endereco_devedor"
                            }
                          })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Bairro",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.ds_bairro_devedor,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "ds_bairro_devedor", $$v)
                          },
                          expression: "titulo.ds_bairro_devedor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-n8" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3" } },
                    [
                      _c("v-text-field", {
                        staticClass: "parcelas",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Cidade",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.nm_cidade_devedor,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "nm_cidade_devedor", $$v)
                          },
                          expression: "titulo.nm_cidade_devedor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "2" } },
                    [
                      _c("v-text-field", {
                        staticClass: "parcelas",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "CEP",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.formatCEPDevedorInline,
                          callback: function($$v) {
                            _vm.formatCEPDevedorInline = $$v
                          },
                          expression: "formatCEPDevedorInline"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "2" } },
                    [
                      _c("v-text-field", {
                        staticClass: "parcelas",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "UF",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.cd_uf_devedor,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "cd_uf_devedor", $$v)
                          },
                          expression: "titulo.cd_uf_devedor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "E-mail do devedor",
                          required: "",
                          readonly: ""
                        },
                        model: {
                          value: _vm.titulo.ds_email_devedor,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "ds_email_devedor", $$v)
                          },
                          expression: "titulo.ds_email_devedor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Telefone do devedor",
                          required: "",
                          readonly: ""
                        },
                        model: {
                          value: _vm.titulo.nu_telefone_devedor,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "nu_telefone_devedor", $$v)
                          },
                          expression: "titulo.nu_telefone_devedor"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.titulo.nu_quantidade_devedores > 1
        ? _c("CardCoodevedores", {
            attrs: {
              titulo: _vm.titulo,
              loading: _vm.loading,
              showInModal: true
            },
            on: { atualizarTitulo: _vm.exibirDetalhe }
          })
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
        [
          _c(
            "v-container",
            [
              _c(
                "p",
                { staticClass: "title" },
                [
                  _c("v-icon", { staticClass: "ma-2" }, [
                    _vm._v("mdi-information-outline")
                  ]),
                  _vm._v("Apontamento "),
                  _vm.superUsuario && _vm.usuarioRecuperi
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-2 pa-2",
                          attrs: {
                            "data-cy": "btnEditarApontamento",
                            loading: _vm.loadingMunicipios,
                            disabled: _vm.loadingMunicipios
                          },
                          on: { click: _vm.ativaBotaoCartorio }
                        },
                        [_vm._v("Editar")]
                      )
                    : _vm._e(),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.superUsuario &&
                                            _vm.usuarioRecuperi,
                                          expression:
                                            "superUsuario && usuarioRecuperi"
                                        }
                                      ],
                                      staticClass: "ma-2 pa-2",
                                      attrs: {
                                        "x-small": "",
                                        elevation: "0",
                                        fab: "",
                                        loading: _vm.loadingMunicipios,
                                        disabled: _vm.loadingMunicipios
                                      },
                                      on: {
                                        click: _vm.ressincronizarMunicipios
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v(" mdi-sync ")])],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", [
                        _vm._v("Ressincronizar listagem de comarcas/municípios")
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {},
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          filled: _vm.desabilitaCartorios,
                          disabled: _vm.desabilitaCartorios,
                          label: "Protocolo do Cartório",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.apontamento_nu_protocolo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.titulo,
                              "apontamento_nu_protocolo",
                              $$v
                            )
                          },
                          expression: "titulo.apontamento_nu_protocolo"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "parcelas",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Data de Apontamento",
                          required: "",
                          filled: _vm.desabilitaCartorios,
                          disabled: _vm.desabilitaCartorios,
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.formatDatacartorioInline,
                          callback: function($$v) {
                            _vm.formatDatacartorioInline = $$v
                          },
                          expression: "formatDatacartorioInline"
                        }
                      }),
                      _c(
                        "v-card",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editarCartorio,
                              expression: "editarCartorio"
                            }
                          ],
                          staticClass: "pa-2"
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "ma-2 pa-2" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "ma-2", attrs: { small: "" } },
                                [_vm._v("mdi-home-city")]
                              ),
                              _vm._v(" Número do Protocolo ")
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: "Protocolo do Cartório",
                                      required: "",
                                      maxlength: "10",
                                      onkeypress: _vm.permitirLetrasNumeros(
                                        false
                                      )
                                    },
                                    model: {
                                      value: _vm.novoProtocolo,
                                      callback: function($$v) {
                                        _vm.novoProtocolo = $$v
                                      },
                                      expression: "novoProtocolo"
                                    }
                                  }),
                                  _c(
                                    "v-card",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.editarCartorio,
                                          expression: "editarCartorio"
                                        }
                                      ],
                                      staticClass: "pa-2 mt-4",
                                      staticStyle: { "box-shadow": "none" }
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "ma-2 pa-2" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ma-2",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-home-city")]
                                          ),
                                          _vm._v(" Data do Apontamento ")
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { lg: "12" } },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-right": 40,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "offset-x": "",
                                                    "min-width": "auto"
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function({
                                                        on,
                                                        attrs
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "maska",
                                                                      rawName:
                                                                        "v-maska",
                                                                      value:
                                                                        "##/##/####",
                                                                      expression:
                                                                        "\n                                                            '##/##/####'\n                                                        "
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "parcelas",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    label:
                                                                      "Clique para selecionar",
                                                                    required:
                                                                      "",
                                                                    locale:
                                                                      "pt-br",
                                                                    "prepend-icon":
                                                                      "mdi-calendar",
                                                                    hint:
                                                                      "Formato: dd/mm/AAAA"
                                                                  },
                                                                  on: {
                                                                    focus:
                                                                      _vm.setFocus,
                                                                    keyup: function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        !$event.type.indexOf(
                                                                          "key"
                                                                        ) &&
                                                                        _vm._k(
                                                                          $event.keyCode,
                                                                          "enter",
                                                                          13,
                                                                          $event.key,
                                                                          "Enter"
                                                                        )
                                                                      )
                                                                        return null
                                                                      return _vm.validarDataDigitada(
                                                                        true,
                                                                        $event
                                                                      )
                                                                    },
                                                                    blur: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.validarDataDigitada()
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.dataDigitavel,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.dataDigitavel = $$v
                                                                    },
                                                                    expression:
                                                                      "\n                                                            dataDigitavel\n                                                        "
                                                                  }
                                                                },
                                                                "v-text-field",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ]),
                                                  model: {
                                                    value: _vm.menu2,
                                                    callback: function($$v) {
                                                      _vm.menu2 = $$v
                                                    },
                                                    expression: "menu2"
                                                  }
                                                },
                                                [
                                                  _c("v-date-picker", {
                                                    attrs: {
                                                      locale: "pt-br",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.selecionarData(
                                                          _vm.data
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.data,
                                                      callback: function($$v) {
                                                        _vm.data = $$v
                                                      },
                                                      expression: "data"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3", md: "9" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", sm: "3", md: "6" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  filled: _vm.desabilitaCartorios,
                                  disabled: _vm.desabilitaCartorios,
                                  label: "Cartório",
                                  required: "",
                                  readonly: !_vm.showActionSalvar
                                },
                                model: {
                                  value: _vm.formatNmCartorio,
                                  callback: function($$v) {
                                    _vm.formatNmCartorio = $$v
                                  },
                                  expression: "formatNmCartorio"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", sm: "3", md: "6" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "parcelas",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: "Endereço do Cartório",
                                  required: "",
                                  filled: _vm.desabilitaCartorios,
                                  disabled: _vm.desabilitaCartorios,
                                  readonly: !_vm.showActionSalvar
                                },
                                model: {
                                  value: _vm.cartorioEndereco,
                                  callback: function($$v) {
                                    _vm.cartorioEndereco = $$v
                                  },
                                  expression: "cartorioEndereco"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0",
                              attrs: { cols: "12", sm: "3", md: "4" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "parcelas",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: "Município de Apontamento",
                                  required: "",
                                  filled: _vm.desabilitaCartorios,
                                  disabled: _vm.desabilitaCartorios,
                                  readonly: !_vm.showActionSalvar
                                },
                                model: {
                                  value: _vm.formatMunicipioApontamento,
                                  callback: function($$v) {
                                    _vm.formatMunicipioApontamento = $$v
                                  },
                                  expression: "formatMunicipioApontamento"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 d-flex flex-row",
                              attrs: { cols: "12", sm: "3", md: "5" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "parcelas",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: "E-mail do Cartório",
                                  required: "",
                                  filled: _vm.desabilitaCartorios,
                                  disabled: _vm.desabilitaCartorios,
                                  readonly: !_vm.showActionSalvar
                                },
                                model: {
                                  value: _vm.cartorioEmail,
                                  callback: function($$v) {
                                    _vm.cartorioEmail = $$v
                                  },
                                  expression: "cartorioEmail"
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.cartorioEmail,
                                      expression: "cartorioEmail"
                                    }
                                  ],
                                  attrs: {
                                    icon: "",
                                    href: `mailto:${this.cartorioEmail}`,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "success--text" },
                                    [_vm._v("mdi-email")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 d-flex flex-row",
                              attrs: { cols: "12", sm: "3", md: "3" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "parcelas",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: "Telefone do Cartório",
                                  required: "",
                                  filled: _vm.desabilitaCartorios,
                                  disabled: _vm.desabilitaCartorios,
                                  readonly: !_vm.showActionSalvar
                                },
                                model: {
                                  value: _vm.telefoneCartorio,
                                  callback: function($$v) {
                                    _vm.telefoneCartorio = $$v
                                  },
                                  expression: "telefoneCartorio"
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.cartorioTelefone,
                                      expression: "cartorioTelefone"
                                    }
                                  ],
                                  attrs: {
                                    icon: "",
                                    href: `tel:${this.cartorioTelefone}`
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "success--text" },
                                    [_vm._v("mdi-phone")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editarCartorio,
                              expression: "editarCartorio"
                            }
                          ],
                          staticClass: "pa-2"
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "ma-2 pa-2" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "ma-2", attrs: { small: "" } },
                                [_vm._v("mdi-home-city")]
                              ),
                              _vm._v(" Selecione o novo Município ")
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "1" } },
                                [
                                  _c("v-progress-circular", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loadingMunicipios,
                                        expression: "loadingMunicipios"
                                      }
                                    ],
                                    staticClass: "ml-5",
                                    attrs: {
                                      color: "primary",
                                      small: "",
                                      indeterminate: ""
                                    }
                                  }),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.iconLateral,
                                          expression: "iconLateral"
                                        }
                                      ],
                                      staticClass: "ml-5",
                                      attrs: { large: "" }
                                    },
                                    [_vm._v("mdi-chevron-right")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "8" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.itensMunicipios.municipios,
                                      dense: "",
                                      loading: _vm.loadingMunicipios,
                                      disabled: _vm.loadingMunicipios,
                                      "item-text": "nm_estado_municipio",
                                      "item-value": "cd_municipio_completo",
                                      outlined: "",
                                      label: "Selecione o Município"
                                    },
                                    on: { input: _vm.buscaCartorio },
                                    model: {
                                      value: _vm.valueMunicipios,
                                      callback: function($$v) {
                                        _vm.valueMunicipios = $$v
                                      },
                                      expression: "valueMunicipios"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "3" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.itensMunicipios.municipios,
                                      dense: "",
                                      outlined: "",
                                      "item-text": "cd_ibge_comarca",
                                      "item-value": "cd_municipio_completo",
                                      label: "Comarca"
                                    },
                                    on: { input: _vm.buscaCartorio },
                                    model: {
                                      value: _vm.valueMunicipios,
                                      callback: function($$v) {
                                        _vm.valueMunicipios = $$v
                                      },
                                      expression: "valueMunicipios"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editarCartorioComarca,
                              expression: "editarCartorioComarca"
                            }
                          ],
                          staticClass: "pa-2 mt-4"
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "ma-2 pa-2" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "ma-2", attrs: { small: "" } },
                                [_vm._v("mdi-home-city")]
                              ),
                              _vm._v(" Selecione o novo Cartório ")
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "1" } },
                                [
                                  _c("v-progress-circular", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loadingCartorios,
                                        expression: "loadingCartorios"
                                      }
                                    ],
                                    staticClass: "ml-5",
                                    attrs: {
                                      color: "primary",
                                      indeterminate: ""
                                    }
                                  }),
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.iconLateralCartorios,
                                          expression: "iconLateralCartorios"
                                        }
                                      ],
                                      staticClass: "ml-5",
                                      attrs: { large: "" }
                                    },
                                    [_vm._v("mdi-chevron-right")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "8" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.itemsCartorios,
                                      dense: "",
                                      "no-data-text":
                                        "Nenhum cartório encontrado para esse município",
                                      "item-text": "nm_cartorio",
                                      "item-value": "nu_cartorio",
                                      outlined: "",
                                      label: "Selecione o Cartório"
                                    },
                                    model: {
                                      value: _vm.valueCartorios,
                                      callback: function($$v) {
                                        _vm.valueCartorios = $$v
                                      },
                                      expression: "valueCartorios"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "3" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.itemsCartorios,
                                      dense: "",
                                      outlined: "",
                                      "item-text": "nu_cartorio",
                                      "item-value": "nu_cartorio",
                                      label: "Número do Cartório"
                                    },
                                    model: {
                                      value: _vm.valueCartorios,
                                      callback: function($$v) {
                                        _vm.valueCartorios = $$v
                                      },
                                      expression: "valueCartorios"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editarCartorio,
                      expression: "editarCartorio"
                    }
                  ],
                  staticClass: "text-right px-0 pt-3 pb-0"
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: { color: "error" },
                      on: { click: _vm.fechaDialogComarca }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-3 white--text",
                      attrs: { color: "green" },
                      on: { click: _vm.salvarDadosComarca }
                    },
                    [_vm._v("Salvar Alterações ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        {
          staticClass: "ma-3 px-3 pt-3 pb-6",
          attrs: { loading: _vm.loadingInstrumento }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "p",
                { staticClass: "title" },
                [
                  _c("v-icon", { staticClass: "ma-2" }, [
                    _vm._v("mdi-information-outline")
                  ]),
                  _vm._v("Instrumento de Protesto ")
                ],
                1
              ),
              !_vm.instrumentoProtesto
                ? _c(
                    "v-row",
                    {},
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { sm: "12", md: "12", lg: "12" }
                        },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                prominent: "",
                                icon: _vm.getIconeAlerta(),
                                shaped: "",
                                type: _vm.getTipoAlerta()
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.getTextoAlerta()) + " "),
                              _vm.permitirSolicitarInstrumento()
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text ml-2",
                                      attrs: {
                                        color: "#46a6f5",
                                        fab: "",
                                        dark: "",
                                        small: ""
                                      },
                                      on: { click: _vm.solicitaInstrumentoProt }
                                    },
                                    [
                                      _c("v-icon", { staticClass: "ma-2" }, [
                                        _vm._v(
                                          "mdi-arrow-top-right-bold-box-outline"
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { staticClass: "mt-2 d-flex align-center" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { cols: "12", sm: "4", md: "4" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: "Nome Anexo",
                              required: "",
                              readonly: "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.instrumentoProtesto.ds_anexo_nome,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.instrumentoProtesto,
                                  "ds_anexo_nome",
                                  $$v
                                )
                              },
                              expression: "instrumentoProtesto.ds_anexo_nome"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3 white--text",
                          attrs: {
                            loading: _vm.baixandoInstrumento,
                            color: "green",
                            width: "120px"
                          },
                          on: { click: _vm.baixarInstrumentos }
                        },
                        [_vm._v("BAIXAR ")]
                      ),
                      _vm.superUsuario
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-3 white--text",
                              attrs: {
                                loading: _vm.baixandoInstrumento,
                                color: "orange darken-3",
                                width: "120px"
                              },
                              on: { click: _vm.modalInvalidarInstrumento }
                            },
                            [_vm._v("INVALIDAR ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm.mostrarAnexoProtesto() && _vm.verificaPermissaoAnexo()
        ? _c(
            "v-card",
            {
              staticClass: "ma-3 px-3 pt-3 pb-6",
              attrs: { loading: _vm.loadingAnexo }
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "p",
                    { ref: "sessaoAnexo", staticClass: "title" },
                    [
                      _c("v-icon", { staticClass: "ma-2" }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v(" Anexo Arquivo de Protesto ")
                    ],
                    1
                  ),
                  _vm.anexoProtesto != null
                    ? _c(
                        "v-row",
                        { staticClass: "mt-2 d-flex align-center" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "4", md: "4" }
                            },
                            [
                              !_vm.arquivosSelecionadosTitulo
                                ? _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: "ID Anexo",
                                      required: "",
                                      readonly: "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.anexoProtesto.ds_anexo_nome,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.anexoProtesto,
                                          "ds_anexo_nome",
                                          $$v
                                        )
                                      },
                                      expression: "anexoProtesto.ds_anexo_nome"
                                    }
                                  })
                                : _c(
                                    "div",
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " Voce tem certeza que deseja substituir o anexo? "
                                        )
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.formatarNomesArquivos()) +
                                          " "
                                      ),
                                      _vm.arquivosSelecionadosTitulo &&
                                      _vm.arquivosSelecionadosTitulo.length
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function({
                                                      on,
                                                      attrs
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "ma-3",
                                                                attrs: {
                                                                  color: _vm.tamanhoArquivosValido(
                                                                    _vm.arquivosSelecionadosTitulo
                                                                  )
                                                                    ? "green"
                                                                    : "red"
                                                                }
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.tamanhoArquivosValido(
                                                                    _vm.arquivosSelecionadosTitulo
                                                                  )
                                                                    ? "done"
                                                                    : "error"
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2277128033
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.tamanhoArquivosValido(
                                                        _vm.arquivosSelecionadosTitulo
                                                      )
                                                        ? "Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título"
                                                        : "A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título"
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                            ],
                            1
                          ),
                          !_vm.arquivosSelecionadosTitulo
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text ml-3",
                                      attrs: {
                                        loading: _vm.loadingAnexo,
                                        color: "green",
                                        width: "120px"
                                      },
                                      on: { click: _vm.baixarArquivoProtesto }
                                    },
                                    [_vm._v("BAIXAR ")]
                                  ),
                                  _vm.titulo.cd_status == 220
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "white--text ml-3",
                                          attrs: {
                                            loading:
                                              _vm.loadingAnexo || _vm.loading,
                                            color: "blue",
                                            width: "auto"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.document
                                                .getElementById(
                                                  "pdfFileInputUpdate"
                                                )
                                                .click()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v("substituir "),
                                          _c(
                                            "v-icon",
                                            { staticClass: "mx-2" },
                                            [_vm._v("mdi-file-replace-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("v-file-input", {
                                    staticStyle: { display: "none" },
                                    attrs: {
                                      accept:
                                        "application/pdf, application/zip",
                                      "hide-input": "",
                                      "prepend-icon": "",
                                      multiple: "",
                                      id: "pdfFileInputUpdate"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.$forceUpdate()
                                      }
                                    },
                                    model: {
                                      value: _vm.arquivosSelecionadosTitulo,
                                      callback: function($$v) {
                                        _vm.arquivosSelecionadosTitulo = $$v
                                      },
                                      expression: "arquivosSelecionadosTitulo"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text ml-3",
                                      attrs: { color: "red", width: "auto" },
                                      on: {
                                        click: function($event) {
                                          _vm.arquivosSelecionadosTitulo = null
                                        }
                                      }
                                    },
                                    [
                                      _vm._v("cancelar "),
                                      _c("v-icon", { staticClass: "ml-2" }, [
                                        _vm._v("mdi-close")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text ml-3",
                                      attrs: {
                                        loading:
                                          _vm.loadingAnexo || _vm.loading,
                                        color: "green",
                                        width: "auto"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.substituirAnexo()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v("confirmar "),
                                      _c("v-icon", { staticClass: "ml-2" }, [
                                        _vm._v("mdi-check")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: {
                                    cols: "6",
                                    xs: "6",
                                    sm: "6",
                                    md: "7",
                                    lg: "7"
                                  }
                                },
                                [_vm._v(" Arquivo(s) anexado(s) ")]
                              ),
                              _c("v-col", {
                                staticClass: "font-weight-bold",
                                attrs: {
                                  cols: "6",
                                  xs: "6",
                                  sm: "6",
                                  md: "5",
                                  lg: "5"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "pa-3 d-flex justify-start align-center",
                                  attrs: {
                                    cols: "6",
                                    xs: "6",
                                    sm: "6",
                                    md: "7",
                                    lg: "7"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formatarNomesArquivos()) +
                                      " "
                                  ),
                                  _vm.arquivosSelecionadosTitulo &&
                                  _vm.arquivosSelecionadosTitulo.length
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "ma-3",
                                                            attrs: {
                                                              color: _vm.tamanhoArquivosValido(
                                                                _vm.arquivosSelecionadosTitulo
                                                              )
                                                                ? "green"
                                                                : "red"
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.tamanhoArquivosValido(
                                                                _vm.arquivosSelecionadosTitulo
                                                              )
                                                                ? "done"
                                                                : "error"
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2277128033
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.tamanhoArquivosValido(
                                                    _vm.arquivosSelecionadosTitulo
                                                  )
                                                    ? "Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título"
                                                    : "A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título"
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _c("v-col", [
                                !_vm.anexoProtesto
                                  ? _c("span", { staticClass: "text-grey" }, [
                                      _c("strong", [
                                        _vm._v("Arquivos aceitos:")
                                      ]),
                                      _vm._v(
                                        " PDF ou ZIP com tamanho inferior à 4,5MB. Certifique-se que os arquivos não possuam carecteres especiais "
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "pa-0 d-flex justify-end align-center",
                                  attrs: {
                                    cols: "4",
                                    xs: "4",
                                    sm: "4",
                                    md: "3",
                                    lg: "3"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text ml-3",
                                      attrs: {
                                        color: "green",
                                        disabled: _vm.anexoProtesto != null,
                                        loading: _vm.loadingAnexo || _vm.loading
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.document
                                            .getElementById("pdfFileInput")
                                            .click()
                                        }
                                      }
                                    },
                                    [_vm._v("SELECIONAR ANEXO(s) ")]
                                  ),
                                  !_vm.pendenteSelecionarArquivos()
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "white--text ml-3",
                                          attrs: {
                                            color: "green",
                                            loading:
                                              _vm.loadingAnexo || _vm.loading,
                                            disabled: !_vm.tamanhoArquivosValido(
                                              _vm.arquivosSelecionadosTitulo
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.anexarArquivoProtesto()
                                            }
                                          }
                                        },
                                        [_vm._v("ENVIAR ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-file-input", {
                            staticStyle: { display: "none" },
                            attrs: {
                              accept: "application/pdf, application/zip",
                              "hide-input": "",
                              "prepend-icon": "",
                              multiple: "",
                              id: "pdfFileInput"
                            },
                            on: {
                              change: function($event) {
                                return _vm.$forceUpdate()
                              }
                            },
                            model: {
                              value: _vm.arquivosSelecionadosTitulo,
                              callback: function($$v) {
                                _vm.arquivosSelecionadosTitulo = $$v
                              },
                              expression: "arquivosSelecionadosTitulo"
                            }
                          })
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
        [
          _c(
            "v-container",
            [
              _c(
                "p",
                { staticClass: "title" },
                [
                  _c("v-icon", { staticClass: "ma-2" }, [
                    _vm._v("mdi-information-outline")
                  ]),
                  _vm._v("Apresentante ")
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-n8" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Razão Social",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: this.$store.getters.nomeApresentante,
                          callback: function($$v) {
                            _vm.$set(
                              this.$store.getters,
                              "nomeApresentante",
                              $$v
                            )
                          },
                          expression: "this.$store.getters.nomeApresentante"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4", md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Código",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.cd_apresentante,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "cd_apresentante", $$v)
                          },
                          expression: "titulo.cd_apresentante"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", sm: "2", md: "3" } }),
                  _c("v-col", { attrs: { cols: "12", sm: "2", md: "3" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
        [
          _c(
            "v-container",
            [
              _c(
                "p",
                { staticClass: "title" },
                [
                  _c("v-icon", { staticClass: "ma-2" }, [
                    _vm._v("mdi-information-outline")
                  ]),
                  _vm._v("Sacador ")
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-n8" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "4" } },
                    [
                      _c("v-text-field", {
                        staticClass: "parcelas",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Nome do Sacador",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.nm_sacador,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "nm_sacador", $$v)
                          },
                          expression: "titulo.nm_sacador"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "4" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["###.###.###-##", "XX.XXX.XXX/XXXX-##"],
                            expression:
                              "['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                          }
                        ],
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "CNPJ/CPF",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.formatDocumentoApresentanteInline,
                          callback: function($$v) {
                            _vm.formatDocumentoApresentanteInline = $$v
                          },
                          expression: "formatDocumentoApresentanteInline"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", sm: "2", md: "4" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
        [
          _c(
            "v-container",
            [
              _c(
                "p",
                { staticClass: "title" },
                [
                  _c("v-icon", { staticClass: "ma-2" }, [
                    _vm._v("mdi-information-outline")
                  ]),
                  _vm._v("Cedente ")
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mb-n8" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2", md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Nome Cedente Favorecido",
                          required: "",
                          readonly: !_vm.showActionSalvar
                        },
                        model: {
                          value: _vm.titulo.nm_cedente_favorecido,
                          callback: function($$v) {
                            _vm.$set(_vm.titulo, "nm_cedente_favorecido", $$v)
                          },
                          expression: "titulo.nm_cedente_favorecido"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", sm: "2", md: "4" } }),
                  _c("v-col", { attrs: { cols: "12", sm: "2", md: "4" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.superUsuario && _vm.usuarioRecuperi
        ? _c(
            "v-card",
            { staticClass: "ma-3 pa-3", attrs: { loading: _vm.loading } },
            [
              _c(
                "v-container",
                [
                  _vm.superUsuario &&
                  _vm.usuarioRecuperi &&
                  _vm.titulo.cd_status === 102
                    ? _c(
                        "p",
                        { staticClass: "title" },
                        [
                          _c("v-icon", { staticClass: "ma-2" }, [
                            _vm._v("mdi-information-outline")
                          ]),
                          _vm._v("Ofertas Protestado ")
                        ],
                        1
                      )
                    : _vm._e(),
                  [
                    _vm.superUsuario &&
                    _vm.usuarioRecuperi &&
                    _vm.titulo.cd_status === 102
                      ? _c(
                          "v-alert",
                          { attrs: { type: _vm.alertTypeOfertas } },
                          [_vm._v(" " + _vm._s(_vm.alertInfoOfertas) + " ")]
                        )
                      : _vm._e()
                  ],
                  _c(
                    "p",
                    { staticClass: "title" },
                    [
                      _c("v-icon", { staticClass: "ma-2" }, [
                        _vm._v("mdi-information-outline")
                      ]),
                      _vm._v("Mensageria ")
                    ],
                    1
                  ),
                  [
                    _vm.superUsuario && _vm.usuarioRecuperi
                      ? _c("v-alert", { attrs: { type: _vm.alertTypeEmail } }, [
                          _vm._v(" " + _vm._s(_vm.alertInfoEmail))
                        ])
                      : _vm._e(),
                    _vm.superUsuario && _vm.usuarioRecuperi
                      ? _c("v-alert", { attrs: { type: _vm.alertTypeCel } }, [
                          _vm._v(" " + _vm._s(_vm.alertInfoCel))
                        ])
                      : _vm._e(),
                    _vm.superUsuario && _vm.usuarioRecuperi
                      ? _c(
                          "v-alert",
                          { attrs: { type: _vm.alertTypeCelCheck } },
                          [_vm._v(" " + _vm._s(_vm.alertInfoCelCheck))]
                        )
                      : _vm._e(),
                    _vm.superUsuario && _vm.usuarioRecuperi
                      ? _c(
                          "v-alert",
                          {
                            attrs: {
                              type: _vm.habilitadoNotifica ? "success" : "info"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.habilitadoNotifica
                                    ? "Notifica: o Apresentante está habilitado na Notifica"
                                    : "Notifica: o Apresentante não está habilitado na Notifica"
                                )
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showActionSalvar,
              expression: "showActionSalvar"
            }
          ],
          staticClass: "mr-4"
        },
        [_vm._v("Salvar")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }